import * as React from 'react';
import styled from 'styled-components';
import { Container } from '../../layout/Container';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Section } from '../../layout/Section';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { TrackItem } from '../../ui/playlist/TrackItem';
import { Seo } from '../../../utils/seo/Index';
import { animated } from 'react-spring';
import { Hero, HeroContent } from '../../ui/hero/Index';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { routeConfig } from '../../../__config__/routes';
import Info from './Info';
import Calculator from './Calc/Calculator';

import { Column } from '../../layout/Column';
import CustomerAdvice from './CustomerAdvice';

import { Logo } from '../../ui/logo/office/Index';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from '../../../utils/hooks/useForm';
import { Form, FormRow } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import Ads from './Ads';
import Card from './Card/Card';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { MAIL_MUTATION } from '../../../../client/__graphql__/mutations/mail';
import { Textarea } from '../../ui/form/Textarea';
import { useScrollToElement } from '../../../utils/hooks/useScrollToElement';
import { GENERAL_VALUATION_MUTATION } from '../../../../client/__graphql__/mutations/valuation';
import { createGTMEvent } from '../../../utils/tracking/gtm';
import { LeadType } from '../../../__types__/generated/globalTypes';
import { PageType } from '../../../__types__/generated/globalTypes';
import { Thanks } from '../../layout/Thanks';
import { Properties } from './Properties';
import { useDigtectiveContext } from '../../../__lib__/digtective/context/hooks/useDigtectiveContext';

const FinancingPage: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [posted, setPosted] = React.useState(false);
  const contactRef = React.useRef<null | HTMLDivElement>(null);
  const { scrollTo } = useScrollToElement();
  const { submitWithDigger } = useDigtectiveContext();

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const [mail, { loading: mailLoading, error }] = useMutation<
    ILeadResponse,
    ILeadInput
  >(MAIL_MUTATION, {
    onCompleted: ({ lead }) => {
      if (lead.mail?.success) {
        setPosted(true);
      }
    }
  });

  const [lead, { error: leadError, loading: leadLoading }] = useMutation(
    GENERAL_VALUATION_MUTATION,
    {
      onCompleted: ({ lead }) => {
        if (lead.valuation?.success) {
          setPosted(true);

          // GTM tracking
          createGTMEvent({
            event: 'pmFormSubmission',
            gaEvent: 'RT_VALUATION',
            gaCategory: 'RT_VALUATION',
            gaAction: 'RT_SEND_VALUATION'
          });
        }
      }
    }
  );

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Eks. Ola Nordmann',
        value: '',
        label: 'Navn *',
        required: true
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: 'Eks. 1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'Eks. ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'textarea',
        name: 'comment',
        label: 'Beskjed *',
        labelAfter: '(Din beskjed her)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields: any = getFields(fields);
      submitWithDigger({ zip: preparedFields.zip }, ({ diggerId }) => {
        lead({
          variables: {
            input: {
              ...preparedFields,
              diggerId,
              referer: `${location.pathname}${location.search}`,
              leadType: LeadType.VERDIVURDERING,
              pageType: PageType.GENERELL
            }
          }
        });
      });
    }
  });

  return (
    <>
      <Seo
        title="Finansering - PrivatMegleren - Nordeas Eiendomsmeglerkjede"
        description="Finansiering av bolig, fritidseiendom eller næringseiendom. Snakk med oss om Boliglån."
        imageTitle="/Boliglån-finansiering-bolig-leilighet-fritidseiendom"
      />
      <Wrapper>
        <Hero
          className="content-height padding-top-150 padding-bottom-80"
          size="cover"
          position="bottom"
          imageUrl="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
          opacity={0.6}
          alt="bilde"
        >
          <HeroContent className="title">
            <Heading tag="h1" center={true}>
              Finansiering av bolig, fritidseiendom og næringseiendom
            </Heading>
          </HeroContent>
        </Hero>
      </Wrapper>
      <Container>
        <Paragraph style={{ padding: '2em' }} center>
          Med finansiering fra Nordea og eiendomsmegler fra PrivatMegleren, får
          du et skreddersydd tilbud som passer deg og dine behov. Sammen sikrer
          vi de beste valgene for deg og din eiendom.
        </Paragraph>
        <ButtonGroup style={{ justifyContent: 'center' }}>
          <ButtonWrapper className="finansiering">
            <Button
              type="primary"
              colorTheme="gold"
              as="a"
              target="_blank"
              href={routeConfig?.nordea_finansiering_2.url}
              style={{ textAlign: 'center' }}
            >
              Søk om finansiering fra Nordea
            </Button>
          </ButtonWrapper>
        </ButtonGroup>
        <Grid className="grid top">
          <Column>
            <Info />
          </Column>
          <Column>
            <Calculator />
          </Column>
        </Grid>
        <CustomerAdvice />
      </Container>
      <Container style={{ paddingTop: '50px' }}>
        <Section style={pageFadeIn}>
          <Heading center tag="h3">
            Mer om finansiering - lytt til Nordeas Podcast
          </Heading>
          <Paragraph center style={{ paddingBottom: '2em' }}>
            Vil du vite mer om finansiering og hva som lønner seg ved kjøp og
            salg av bolig? Ta en lytt til Nordea’s podcast. Under kan du høre
            noen utvalgte episoder som handler om nettopp finansiering og
            eiendom.
          </Paragraph>
          <Playlist />
          <LogoWrapper>
            <Logo
              className="logo"
              alt="bilde av logo"
              style={{ margin: '5em auto' }}
            />
          </LogoWrapper>
          <Heading center tag="h3">
            PrivatMegleren i ditt nærområde
          </Heading>
          <Paragraph center>
            Utstyrt med markedets mest innovative løsninger, sammen med en
            dyptgående og grunnleggende tilnærming, er målet vårt å få flest
            mulig budgivere på enhver eiendom vi omsetter. Vi kjenner
            boligmarkedet til enhver tid. Vårt nettverk, erfaring og
            skreddersydde leveranse med optimal håndtering av alle
            interessenter, sikrer deg et godt salg og et riktig kjøp av eiendom.
          </Paragraph>
          <Paragraph center>
            <a
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                if (typeof scrollTo === 'function') {
                  scrollTo({
                    ref: contactRef,
                    distanceTop: 100,
                    behaviour: 'smooth'
                  });
                }
              }}
            >
              Kontakt oss
            </a>{' '}
            gjerne dersom du ønsker å vite mer om hvordan din eiendom vil
            fremstå gjennom PrivatMegleren.
          </Paragraph>
          <Heading center tag="h3">
            Eiendommer til salgs
          </Heading>
          <Paragraph style={{ paddingBottom: '1em' }} center>
            Vurderer du å kjøpe en{' '}
            <a href="https://privatmegleren.no/kjope-bolig?a=true&c=null&l=%5B%5D&mb=0&mv=false&p=1&pfv=1000000&ptv=25000000&q=&s=false&sd=BRUKT&sfv=30&show_market_link=false&stv=400">
              brukt bolig
            </a>
            ,{' '}
            <a href="https://privatmegleren.no/kjope-bolig?a=true&c=null&l=%5B%5D&mb=0&mv=false&p=1&pfv=1000000&ptv=25000000&q=&s=false&sd=NYBYGG&sfv=30&show_market_link=false&stv=400">
              nybygg
            </a>{' '}
            eller <a href="https://privatmegleren.no/fritid">fritidseiendom</a>?
            Vi gir deg gjerne veiledning og tips om hvordan du kan utnytte din
            økonomi på best mulig måte.
          </Paragraph>
        </Section>
      </Container>
      <Properties />
      <Container>
        <Ads>
          <Card
            description="La oss hjelpe deg til et bedre eiendomskjøp<br/>Les mer om kjøpsmegling."
            logo="https://cdn.reeltime.no/pm_assets/img/cards/kjop_logo.png"
            background="https://cdn.reeltime.no/pm_assets/img/cards/kjop_bg.png"
            link="https://privatmegleren.no/kjop"
          />
          <Card
            background="https://cdn.reeltime.no/pm_assets/img/cards/art.jpg"
            button
            buttonText="Finn kontor"
            link="https://privatmegleren.no/kontorer?alle"
            title="Vurderer du salg<br/>av eiendom?"
            className="card-split"
          />
          <Card
            background="https://cdn.reeltime.no/pm_assets/img/cards/stairs.png"
            button
            buttonText="Søk adresse"
            link="https://privatmegleren.no/verdivurdering-boligkalkulator"
            title="PrivatMegleren<br/>Verdi"
            description="La oss gi deg et estimat på hva eiendommen er verdt."
          />
        </Ads>

        <FormWrapper ref={contactRef} style={fadeOut}>
          <Form
            onSubmit={handleSubmit}
            noValidate
            style={{ paddingBottom: '50px' }}
          >
            <Heading
              style={{ textAlign: 'center', paddingTop: '2em' }}
              tag="h2"
            >
              Bli kontaktet
            </Heading>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}

            {fields
              .filter((item) => item?.type !== 'textarea')
              .map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            {fields
              .filter((item) => item?.type === 'textarea')
              .map((item, index) => {
                return (
                  <FormRow
                    className="span questionTextArea"
                    key={`field_${index}`}
                  >
                    <Textarea
                      rows={4}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Send
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig.personvern.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
    </>
  );
};

export default FinancingPage;

const ReferencesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 7em;
  padding-bottom: 7em;

  @media all and (min-width: 830px) {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 7em;
  }
`;

const Playlist: React.FC = () => {
  const arr: Array<{ id: string; title: string; length: string; url: string }> =
    [
      {
        id: 'track_1',
        title: 'Nyttige råd til budrunden',
        length: '15:21',
        url: 'https://soundcloud.com/user-871377367/pengetreet-budrunden'
      },
      {
        id: 'track_2',
        title: 'Hvordan finne riktig eiendom for deg?',
        length: '11:13',
        url: 'https://soundcloud.com/user-871377367/pengetreet-boligkjopet'
      },
      {
        id: 'track_3',
        title: 'Alt du trenger å vite om nybygg',
        length: '12:27',
        url: 'https://soundcloud.com/user-871377367/pengetreet-nybygg'
      }
    ];

  return (
    <>
      <StyledPlaylist>
        {arr.map((item, index) => (
          <TrackItem
            key={item.id}
            number={index + 1}
            title={item.title}
            length={item.length}
            url={item.url}
          />
        ))}
      </StyledPlaylist>
    </>
  );
};

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  @media all and (min-width: 600px) {
    max-width: 30em;
  }
`;

const StyledPlaylist = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 30px;
  list-style: none;
`;

const Wrapper = styled.div`
  .content-height {
    height: auto;
    min-height: 307px;
  }
`;

const FormWrapper = styled(animated.div)`
  grid-column: span 2;
  .questionTextArea {
    grid-column: span 2;
  }
  textarea {
  }
  .questionForm {
    gap: 1em;
    .raagiving-gruppe {
      grid-column: span 2;
    }

    @media all and (min-width: 600px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;
const LogoWrapper = styled.div`
  .logo {
    width: 100%;
    height: 70px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 9em;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  margin-top: 2em;
  margin-bottom: 2em;

  @media all and (max-width: 400px) {
    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.top {
    align-items: flex-start;
  }

  .custom {
    padding: 0 3rem;
    text-align: center;
  }

  @media all and (min-width: 600px) {
    .custom {
      padding: 0 3rem;
      text-align: left;
    }
  }

  .subtitle {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: -15px;
    font-weight: bold;
    font-size: 1.2rem;
  }

  @media all and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
  }
`;
